import base64js from 'base64-js';

const cloudfrontHostname = 'https://d2obrjcl8a4u8e.cloudfront.net';
const s3Hostname = 'ntmg-media.s3.us-west-1.amazonaws.com';
const s3BucketName = 'ntmg-media';

// Current resizing strategy is to resize to ~2x of the maximum width/height that the
// image will be displayed at for crispness on retina screens.

type ImageEditRequest = {
  resize: {
    height?: number;
    width?: number;
    fit: 'cover' | 'contain' | 'fill' | 'inside' | 'outside';
  };
};

export const getFalconUIImageUrl = ({
  src,
  width,
  height,
}: {
  src: string;
  width?: number;
  height?: number;
}): string => {
  if (!width && !height) {
    return src;
  }

  return getResizedUrl(src, {
    resize: {
      width,
      height,
      fit: 'cover',
    },
  });
};

export const getImageUrl = (originalImageUrl: string, width: number, height?: number): string =>
  getResizedUrl(originalImageUrl, {
    resize: {
      width,
      height,
      fit: 'cover',
    },
  });

const getResizedUrl = (originalImageUrl: string, imageEditRequest: ImageEditRequest): string => {
  if (!originalImageUrl) {
    return '';
  }

  if (!originalImageUrl.includes(s3Hostname)) {
    return originalImageUrl;
  }

  const parts = originalImageUrl.split(s3Hostname + '/');
  if (parts.length !== 2) {
    return originalImageUrl;
  }

  const key = parts[1]
    .split('/')
    .map((pathPart) => decodeURIComponent(pathPart))
    .join('/');
  const imageRequest = {
    bucket: s3BucketName,
    key,
    edits: imageEditRequest,
  };

  const isServer = typeof window === 'undefined';
  if (isServer) {
    const buf = Buffer.from(JSON.stringify(imageRequest));
    return `${cloudfrontHostname}/${buf.toString('base64')}`;
  }

  return `${cloudfrontHostname}/${base64js.fromByteArray(
    Uint8Array.from(new TextEncoder().encode(JSON.stringify(imageRequest)))
  )}`;
};

export const getLogoUrl = (originalImageUrl: string): string => {
  return getResizedUrl(originalImageUrl, {
    resize: {
      height: 48,
      fit: 'cover',
    },
  });
};

export const getThumbnailUrl = (originalImageUrl: string, width?: number): string => {
  return getResizedUrl(originalImageUrl, {
    resize: {
      width,
      fit: 'cover',
    },
  });
};

export const getDetailImageUrl = (originalImageUrl: string): string => {
  return getResizedUrl(originalImageUrl, {
    resize: {
      height: 800,
      fit: 'cover',
    },
  });
};

export const getBannerImageUrl = (originalImageUrl: string, width?: number): string => {
  return getResizedUrl(originalImageUrl, {
    resize: {
      height: 600,
      fit: 'cover',
      width,
    },
  });
};

export const getBackgroundBannerImageUrl = (
  originalImageUrl: string,
  screenWidth: 768 | 600 | 400 | null
): string => {
  let width = 2000;
  if (screenWidth) {
    width = screenWidth * 1.5;
  }

  return getResizedUrl(originalImageUrl, {
    resize: {
      fit: 'cover',
      width,
    },
  });
};
